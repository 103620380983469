/**
* .header--logo
*/ 

.header--logo {

	max-width: 70px;
	margin-left: $sp-medium;
	margin-right: auto;
	margin-top: $sp-extra-small;

	a {

		display: block;
		height: inherit;

	}

	svg {

		display: block;
		width: 100%;
		height: auto;
		max-height: 35px;
		fill: $color-white;

	}

	@include bp($tablet) {
		
		margin-left: $sp-medium;
		margin-right: $sp-medium;
		max-width: 70px;
		margin-top: 0;
		
	}

}