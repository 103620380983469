/**
* .tutorials
*/

body[class*="tutorial"] {

	main {

		a:not(.button) {

			color: darken($color-brand-two, 20%);

			&:hover {

				color: $color-dark-grey;

			}

		}

		.button {

			background-color: $color-brand-two;

		}

		li:before {

			background-color: darken($color-brand-two, 20%);

		}

	}

}

.tutorials {

	background-color: $color-off-white;

	&--header {

		background-color: $color-brand-two;
		padding-top: $sp-large;
		padding-bottom: $sp-large;

		@include bp($tablet) {

			padding-top: 7.5%;
			padding-bottom: 7.5%;
				
		}

		h1 {

			color: $color-dark-grey;
			margin-bottom: 0;

			@include bp($tablet) {
				
				font-size: calc(#{$h1} + 2vw);

			}

		}

		p {

			color: $color-dark-grey;
			margin-top: $sp-large;

			@include bp($tablet) {
				
				max-width: 700px;
				font-size: $fz-l;

			}

		}

	}

	&--modules {

		margin-bottom: $sp-large;

		p {

			font-weight: $fw-bold;
			width: 100%;

			@include bp($tablet) {
				
				margin-bottom: 0;
				margin-right: $sp-extra-small;
				width: auto;

			}

		}

		.module:not(.button) {
	
			background-color: $color-light-grey;
			color: $color-dark-grey;
			font-weight: $fw-bold;
			border-radius: 3px;
			margin-right: $sp-extra-small;
			padding: $sp-extra-small / 2 $sp-extra-small;
			text-decoration: none;

			&:hover {
				
				background-color: $color-brand-one;
				color: $color-white;

			}

		}

	}

	&--roll {
	
		background-color: transparent;

		.flex:after {

			content: '';
			@extend .third;
			@extend .wGutter;

		}

	}
	
}

/**
* .tutorial
*/

article.tutorial {

	background: $color-white;
	position: relative;
	z-index: 0;

	.tutorial--mask {

		position: absolute;
		z-index: 5;
		top: 0;
		left: 0;
		background-color: rgba($color-white, 0.85);
		height: 100%;
		width: 100%;

		p {

			font-weight: 900;
			padding: $sp-medium;
			margin-bottom: $sp-extra-small;
			display: block;
			margin: 0 auto;
			width: 80%;
			font-size: $fz-l;

		}

	}

	.tutorial--mask__content {

		text-align: center;
		position: absolute;
		z-index: 5;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		width: 100%;


	}

}

/**
* section.tutorial
*/

section.tutorial {

	.tutorials--header {

		p {

			margin-top: $sp-medium;
			margin-bottom: 0;

		}

	}

	.tutorial--module {

		margin-top: $sp-small;

		p {

			display: inline-block;
			margin-top: 0;
			margin-bottom: 0;

		}

		a:not(.button) {

			font-size: $fz-l;
			margin-left: $sp-extra-small;
			color: $color-dark-grey;

		}

	}

	.tutorial--content {

		font-size: 20px;

		p {

			line-height: 1.6;

		}

		h2,
		h3,
		h4 {

			border-bottom: 1px solid $color-light-grey;
			padding-bottom: $sp-small;

		}

		@include bp($tablet) {
			
			max-width: 800px;

		}

	}

	.gallery {

		.image {

			border: 1px solid $color-light-grey;
			padding: $sp-extra-small;
			margin-bottom: $sp-small;

		}

		p {
	
			margin-top: $sp-extra-small;
			margin-bottom: 0;
			font-style: italic;

		}

	}

}

/**
* article.membership
*/

article.membership {

	display: block;
	margin: 0 auto;
	max-width: 500px;

	h2 {

		margin-bottom: $sp-small;

	}

	.button {
	
		margin-bottom: 0;

	}

	&:not(:last-child) {

		padding-bottom: $sp-medium;
		margin-bottom: $sp-medium;
		border-bottom: 1px solid $color-light-grey;

	}

}