/**
* .post-navigation
*/ 

.post-navigation {

	background: $color-grey;
	padding-top: $sp-medium;
	padding-bottom: $sp-medium;

	h4 {

		color: $color-white;

	}

	/**
	* .nav-links
	*/ 
	
	.nav-links {
	
		@extend .wrapper;
		@extend .small;
		@extend .tac;
	
	}

	/**
	* .nav-previous,
	.nav-next
	*/ 
	
	.nav-previous,
	.nav-next {
		
		display: block;
		padding-left: $sp-small;
		padding-right: $sp-small;
		color: $color-brand-one;

		@include bp($tablet) { 
		
			display: inline-block;
		
		}
	
	}

}

/**
* .workshops-template-default
*/ 

.workshops-template-default {

	.post-navigation {

		background: $color-light-grey;

		h2 {

			text-align: center;

		}

		a {

			color: $color-grey;

		}

		.nav-links > div {
	
			display: block;
			margin-bottom: $sp-extra-small;

			@include bp($tablet) {
				
				margin-bottom: 0;
				display: inline-block;
				padding-left: $sp-small;
				padding-right: $sp-small;
				
			}

		}

	}

}

/**
* .pagination
*/

section.pagination {

	padding-top: $sp-medium;
	padding-bottom: $sp-medium;
	background-color: transparent;

	div.pagination.col > * {

		width: $sp-medium;
		height: $sp-medium;
		line-height: $sp-medium;
		border-radius: 3px;
		background: $color-light-grey;
		text-align: center;
		margin-left: $sp-extra-small;
		margin-right: $sp-extra-small;
		font-weight: $fw-bold;
		text-decoration: none;
		display: inline-block;

		&.next .fa-chevron-right {
			
			display: inline-block;
		    vertical-align: middle;
		    margin-top: -2.5px;
			height: $sp-small;
			width: $sp-small;
			background-image: url('../resources/chevron-right.svg');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;

		}

		&.prev .fa-chevron-left {
			
			display: inline-block;
		    vertical-align: middle;
		    margin-top: -2.5px;
			height: $sp-small;
			width: $sp-small;
			background-image: url('../resources/chevron-left.svg');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;

		}

		&a {

			background-color: $color-brand-one;
			color: $color-white;
			backface-visibility: hidden;

			&:hover {

				text-decoration: none;
				background-color: rgba($color-brand-one, 0.7);

			}

		}

	}

}