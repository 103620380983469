/**
* .account-menu-item
*/

.account-menu-item {

	a {

		max-width: 100%;
		@include truncate;

		@include bp($desktop) {
			
			max-width: 130px;

		}

		@include bp($max-wrapper) {
			
			max-width: 100%;

		}

	}

}