/**
* nav
*/ 

nav {

	/**
	* list item
	*/ 

	li { 
		
		display: block;
		list-style: none;
		position: relative;
		z-index: 0;

		@include bp($tablet) { 
		
			display: inline-block;
		
		}

		/**
		* anchor
		*/ 

		a {

			color: $color-white;
			display: block;
			padding-left: $sp-extra-small;
			padding-right: $sp-extra-small;
			font-size: $fz-l;
			font-weight: $fw-light;

			@include bp($tablet) {

				font-size: 0.8rem;

			}

			@include bp($desktop) {
			
				padding-left: $sp-small;
				padding-right: $sp-small;
				font-size: 1.05rem;
				
			}

		}

		/**
		* &.menu-item-has-children
		*/ 
		
		&.menu-item-has-children {
		
			&:hover {

				/**
				* .sub-menu
				*/ 
				
				.sub-menu {
				
					opacity: 1;
					transform: translateY(0) translateX(-50%);
				
				}

			}
		
		}

	}

	/**
	* top nav specifics
	*/ 

	&.top--navigation {
		
		display: none;

		a {

			overflow: hidden;

		}
		
		&.active {
			
			position: fixed;
			background: $color-grey;
			z-index: 5;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			opacity: 0;
			padding: $sp-extra-large $sp-large $sp-large;
			@include animation(fi);

			ul {

					width: 100%;

			}

			/**
			* li
			*/ 
			
			li {
			
				@include animation(fifb);
				margin-bottom: $sp-small;
			
			}

			a {
		
				padding: $sp-extra-small;

			}

		}

		&.inactive {

			@include animation(fo);

			/**
			* li
			*/ 
			
			li {
			
				@include animation(fotb);
			
			}

		}

		@include bp($tablet) { 
		
			display: block;
		
		}

		/**
		* li
		*/ 
		
		li {
		
			/**
			* a
			*/ 
			
			a {
			
				padding-top: $sp-small;
				padding-bottom: $sp-small;
				padding-left: $sp-extra-small;
				padding-right: $sp-extra-small;
				line-height: 1.1;
				position: relative;
				z-index: 1;

				@include bp($max-wrapper) {
			
					padding: $sp-medium $sp-small;

				}

				&:hover {

					text-decoration: none;
					color: $color-brand-one;

				}

			}

			/**
			* current
			*/

			&.current-menu-item {

				a {

					color: $color-brand-one;
					
				}

			}
		
		}

	}

	/**
	* .sub-menu
	*/ 

	.sub-menu {

		@include transition;
		opacity: 0;
		transform: translateY($sp-medium) translateX(-50%);
		position: absolute;
		top: 100%;
		left: 50%;
		background: $color-white;
		border-radius: $sp-extra-small / 2;
		width: $sp-extra-large * 1.5;
		box-shadow: 0 0 35px 3px rgba($color-black, 0.11);

		li {
		
			display: block;
			overflow: hidden;

			&:first-of-type {

				border-top-right-radius: $sp-extra-small / 2;
				border-top-left-radius: $sp-extra-small / 2;

			}

			&:last-of-type {

				border-bottom-right-radius: $sp-extra-small / 2;
				border-bottom-left-radius: $sp-extra-small / 2;

			    a {

			    	border-bottom: none;

			    }

			}

			a {

				color: $color-grey;
				padding: $sp-small;
				padding-top: $sp-small;
				padding-bottom: $sp-small;
				border-bottom: 1px solid lighten($color-light-grey, 10%);

				&:hover {

					background: lighten($color-light-grey, 10%);
					color: $color-dark-grey;

				}

			}

		}

		&:before {

			content: '';
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 100%;
			width: 0; 
			height: 0; 
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-bottom: 5px solid $color-white;

		}

	}
	
}

/**
* .top--navigation__toggle
*/ 

.top--navigation__toggle {

	margin-right: $sp-small;

	&.active {

		position: fixed;
		z-index: 10;
		top: 7.5px;
		right: 0;

	}

	@include bp($tablet) { 
	
		display: none;
	
	}

}	