/**
* button,
.button,
input[type="submit"]
*/ 

button,
.button,
input[type="submit"] {

	font-family: inherit;
	background: $color-brand-one;
	display: inline-block;
	border: none;
	outline: none;
	font-size: inherit;
	height: $sp-default-height * 1.15;
	line-height: $sp-default-height * 1.15;
	padding-left: $sp-small * 1.25;
	padding-right: $sp-small * 1.25;
	color: $color-dark-grey;
	margin-bottom: $sp-small;
	cursor: pointer;
	font-weight: $fw-bold;
	box-shadow: 0 $sp-extra-small / 2 5px 0 rgba($color-black, 0.1);
	@include transition;
	width: auto;
	padding-top: 0;
	letter-spacing: 0.045rem;
	appearance: none;

	@include bp($tablet) {

		&.large {
		
			height: $sp-large;
			line-height: $sp-large;
			padding-left: $sp-medium;
			padding-right: $sp-medium;
			font-size: $fz-l;

		}
			
	}

	&.neg {

		background-color: $color-neg;

	}

	&:hover {
	
		color: $color-white;
		background: darken($color-brand-one, 20%);
		text-decoration: none;
		box-shadow: 0 $sp-extra-small / 2 5px 0 rgba($color-black, 0.25);

	}

	/**
	* span
	*/ 
	
	span {
	
		display: inline-block;
		vertical-align: middle;
	
	}

	/**
	* svg
	*/ 
	
	svg {
	
		display: inline-block;
		fill: $color-white;
		vertical-align: middle;
		width: 15px;

	}

}		