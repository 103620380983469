/**
* ul
*/ 

ul {

	margin-bottom: 0;
	list-style: none;

	&.disc {

		@extend .mdt;
		@extend .mdb;
		@extend .pdl--l;
		list-style: disc;
		list-style-position: inside;

	}

}

/**
* li
*/ 

li {

	color: inherit;
	font-weight: $fw-light;

}

/**
* main
*/ 

main {

	ul {

		margin-bottom: $sp-medium;

		li {

			padding-left: $sp-small;
			position: relative;
			z-index: 0;
			margin-bottom: $sp-extra-small / 1.5;

			&:before {

				content: '';
				position: absolute;
				top: $sp-small - 2px;
				left: 0;
				width: 5px;
				height: 5px;
				background: $color-brand-one;
				border-radius: 100%;
					
			}

		}

	}	

}