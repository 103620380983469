.book {
	background-color: $color-brand-one;
	color: $color-white;

	h2,
	h3 {
		color: $color-white;
		font-weight: 500;
	}
	
	.wrapper {
		position: relative;
		z-index: 0;
	}

	&-inner {
		max-width: 620px;
		margin-left: auto;
		margin-right: auto;
	
		@include bp(900) {
			max-width: inherit;
		}
	}

	&-image {
		img {
			display: block;
			max-width: 620px;
			margin-left: auto;
			margin-right: auto;
			border: 10px solid white;
			box-shadow: 0 0 99px rgba(0, 0, 0, 0.15);

			@include bp(900) {
				max-width: 80%;
			}
		}
	}

	&-description {
		@include bp(900) {
			padding-right: $sp-large;
		}
	}

	.button {
		background-color: $color-white;
		color: $color-brand-one;
	}
}