/**
* .woocommerce--acount
*/

.woocommerce--account {

	.woocommerce {

		@extend .flex;
		@extend .justifyBetween;

		.woocommerce-MyAccount-navigation {

			@extend .third;
			@extend .wGutter;
			background-color: $color-off-white;

			ul {

				margin-bottom: 0;
				padding: $sp-extra-small;
				list-style: none;

			}		

			li {

				display: block;
				padding-left: 0;
				margin-bottom: 0;

				&:before {

					content: none;

				}

			}

			a {

				font-weight: $fw-bold;
				padding-top: $sp-extra-small / 2;
				padding-bottom: $sp-extra-small / 2;
				color: $color-grey;

			}

		}

		.woocommerce-MyAccount-content {

			@extend .twoThirds;
			@extend .wGutter;

		}

	}

	/**
	* .membership
	*/
	
	.membership {

		&:not([data-status="completed"]) {
			
			.membership--body {

				opacity: 0.35;

			}
	
		}

		&:not(:last-child) {

			padding-bottom: $sp-small;
			margin-bottom: $sp-small;
			border-bottom: 1px solid $color-off-white;

		}
	
		&--orderNo {

			margin-bottom: 0;

		}	

		&--actions {

			@include bp($tablet) {
				
				text-align: right;

			}

		}

		button {

			margin-bottom: 0;

		}

		.product--title {
			
			margin-bottom: 0;

		}
	
	}

}