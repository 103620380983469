/**
* a
*/ 

a {

	@include transition;
	color: inherit;
	font-weight: 700;
	text-decoration: none;
	color: darken($color-brand-one, 20%);

	&:hover {

		color: $color-brand-one;
	
	}

}