/**
* table
*/ 

table {

	border: none;
	border-collapse: collapse;
	margin-bottom: $sp-medium;
	width: 100%;

	img {

		width: $sp-large;
		margin: 0 auto;
		display: inline-block;
		vertical-align: middle;

	}

}

/**
* thead
*/ 

thead {

	display: none;

	@include bp($tablet) { 
	
	    display: table-header-group;
	
	}

	th {

		background: $color-light-grey;

	}

}

/**
* tbody
*/

tbody,
tfoot {

	th {

		background-color: transparent;
		border-right: 1px solid $color-light-grey;
		width: auto;

	}

}

/**
* th
*/ 

th {

	padding: $sp-extra-small;
	display: block;

	@include bp($tablet) { 
	
		display: table-cell;
	
	}

}

/**
* tr
*/ 

tr {

	display: block;
	margin-bottom: $sp-extra-small;
	width: 100%;
	border: 1px solid $color-light-grey;
	margin: -1px;

	@include bp($tablet) {

		display: table-row;
		
		&:nth-of-type(even) {

			background: $color-off-white;

		}

	}

}	

/**
* td
*/ 

td {

	border: none;
	vertical-align: text-top;
	padding: $sp-extra-small $sp-small;
	display: block;

	&:first-of-type {

		font-weight: $fw-normal;

	}

	&.product-thumbnail {

		max-width: $sp-large;

	}

	&.product-quantity {

		max-width: $sp-extra-large;

	}

	&.actions {

		padding: $sp-small;

	}

	@include bp($tablet) { 
	
		display: table-cell;
		border-right: 1px solid $color-light-grey;
	
	}

}