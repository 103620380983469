/**
* .woocommerce-message
*/

.woocommerce-message {
	
	padding: $sp-extra-small;
	padding-left: $sp-small;
	background-color: $color-off-white;
	margin-bottom: $sp-medium;

	&[role=alert] {

		border-left: 3px solid #e6a858;

	}

}	