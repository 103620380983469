/**
* .woocommerce
*/

.woocommerce {

	table {

		.coupon {

			overflow: hidden;

			label {

				float: left;
				width: 100%;

			}

			input.input-text {

				float: left;
				width: 100%;
				margin-bottom: $sp-extra-small;
				height: 40px;
				line-height: 40px;

				@include bp($tablet) {
					
					margin-bottom: 0;

				}

			}

			button {

				width: 150px; 
				text-align: center;
				float: left;
				height: 40px;
				line-height: 40px;
				margin-left: -1px;

				@include bp($tablet) {
					
					margin-bottom: 0;

				}

			}

			@include bp($tablet) {
				
				float: left;
				width: 50%;

				input.input-text {

					max-width: calc(100% - 150px);

				}

			}

		}

		button[name="update_cart"] {

			float: right;

		}

	}

}

/**
* .cart-collaterals
*/

.cart-collaterals,
.woocommerce-checkout-review-order {

	table {

		width: auto;
		min-width: 420px;

		tbody th,
		tfoot th {

			text-align: left;
			width: auto;
			background-color: transparent;
			border-right: 1px solid $color-light-grey;
			padding-left: $sp-small;

		}

	}

}