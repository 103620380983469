/**
* .woocommerce-form-coupon-toggle
*/

.woocommerce-form-coupon-toggle {

	display: none;

}

/**
* .woocommerce-input-wrapper
*/

.woocommerce-input-wrapper {

	display: block;
	width: 100%;

}