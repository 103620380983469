/**
* Truncate
*/

@mixin truncate() {

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

}