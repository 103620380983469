/**
* header
*/ 

header {

	color: $color-white;
	position: fixed;
	z-index: 5;
	top: 0;
	left: 0;
	width: 100%;
	background: -moz-linear-gradient(top, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,0.75) 0%,rgba(0,0,0,0) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0.75) 0%,rgba(0,0,0,0) 100%);
	@include transition;
	padding-top: 10px;
	padding-bottom: 10px;
	max-height: 85px;

	&.active {

		background: $color-black;
		padding-top: $sp-extra-small;
		padding-bottom: $sp-extra-small;

		ul li a {

			padding-top: $sp-small;
			padding-bottom: $sp-small;

		}

	}
	
}